import React from "react"
import Layout from "../../components/layout"

import { Helmet } from "react-helmet"
import { Container, Row, Col, Nav } from "react-bootstrap"

const Patents = () => {
  return (
    <>
      <Helmet bodyAttributes={{ class: "info short" }} />
      <Layout>
        <Container className="info-contain">
          <Row>
            <Col lg={10}>
              <h1 className="page-title">Publications</h1>
              <div className="publication-section">
                <Nav variant="tabs" defaultActiveKey="/publications/patents">
                  <Nav.Item>
                    <Nav.Link href="/publications/all">All</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/publications/articles">Articles</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/publications/chapters">Chapters</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/publications/patents">Patents</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <p className="resume-text">
                <span className="bold author">PATENTS</span>
              </p>
              <div className="publication-unit-first">
                <p className="publication-title">
                  [01] Development of specific antibodies for European hake{" "}
                  <span className="bold-italic">(Merluccius merluccius)</span>{" "}
                  and a method to assign individuals to its population of origin
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span>{" "}
                  <span className="author">E. G. Gonzalez</span> &amp; J. M.
                  Bautista
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2015
                </p>
                <p className="publication-name">
                  <span className="text-muted">Publication Number:</span> ES 2
                  526 468 A1
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Patents
